// eslint-disable-next-line import/no-unresolved
import ApiSearchResponse from 'prismic-javascript/types/ApiSearchResponse'

export const prismicHomePageContent: ApiSearchResponse = {
  page: 1,
  results_per_page: 20,
  results_size: 2,
  total_results_size: 2,
  total_pages: 1,
  next_page: 'null',
  prev_page: 'null',
  results: [
    {
      id: 'Xvyn1REAACMAsLcN',
      uid: 'null',
      type: 'home_page_updates',
      href: 'https://world-community-grid.cdn.prismic.io/api/v2/documents/search?ref=X_XcGxEAACMAsRlP&q=%5B%5B%3Ad+%3D+at%28document.id%2C+%22Xvyn1REAACMAsLcN%22%29+%5D%5D',
      tags: [],
      first_publication_date: '2020-07-01T15:12:26+0000',
      last_publication_date: '2021-01-06T15:49:47+0000',
      slugs: ['africa-rainfall-project-makes-plans-for-data-storing-and-sharing', 'this-is-how-a-global-community-unites-to-find-cancer-biomarkers.'],
      lang: 'en-us',
      alternate_languages: [],
      data: {
        body: [
          {
            primary: {
              subject: 'Research Update from the MCM Team (March 2025)',
              summary: 'this update examines DYNLT1 (Dynein Light Chain Tctex-Type 1), a gene with pivotal roles in intracellular transport and cancer pathophysiology.',
              date: '2025-03-18',
              image: {
                url: '/images/prismic/mcm1_dynlt1_fig3.jpg',
              },
              link: 'https://worldcommunitygrid.org/about_us/article.s?articleId=822',
            },
          },	
          {
            primary: {
              subject: 'In Memory of Dylan Bucci Challenge Concludes, Over 600 CPU Years Towards Cancer Research',
              summary: 'Sisler Cyber Academy ranked #1 at the end of the challenge started on February 19th and lasting for 19 days.',
              date: '2025-03-12',
              image: {
                url: 'https://lh5.googleusercontent.com/wgCcZ8TaZKLjxSU2WaVTq2mQ0WuoO2nMKLZr03Ob1UXunNeKXWcN0ue_n3oMt12Fw_H6vl7Qm8qROwuykG9lU6-NeYl93eJrDQoEu9sGSZV3JjqSlkUGhOwmEHo6wyGf0RAXXOp0fx37nz3OoWz7Lr4',
              },
              link: 'https://www.worldcommunitygrid.org/about_us/article.s?articleId=821',
            },
          },
          {
            primary: {
              subject: 'Research Update from the MCM Team (January 2025)',
              summary: 'emerging evidence suggests GCM1 mutations are implicated in various cancers, including lung adenocarcinoma (ADC)',
              date: '2025-01-21',
              image: {
                url: '/images/prismic/mcm1_gene_update_gcm1_fig2_B.png',
              },
              link: 'https://worldcommunitygrid.org/about_us/article.s?articleId=819',
            },
          },
        ],
      },
    },
    {
      id: 'Xvu1qxEAACMArIvY',
      uid: 'null',
      type: 'home_page_hero',
      href: 'https://world-community-grid.cdn.prismic.io/api/v2/documents/search?ref=X_XcGxEAACMAsRlP&q=%5B%5B%3Ad+%3D+at%28document.id%2C+%22Xvu1qxEAACMArIvY%22%29+%5D%5D',
      tags: [],
      first_publication_date: '2020-06-30T22:02:11+0000',
      last_publication_date: '2021-01-05T19:32:17+0000',
      slugs: ['home--world-community-grid', 'kevin--home--world-community-grid', 'aids-is-constantly-evolving.so-are-the-tools-to-fight-it.'],
      lang: 'en-us',
      alternate_languages: [],
      data: {
        page_title: 'Home | World Community Grid',
        page_description:
          'World Community Grid enables anyone with a computer or Android device to donate their unused computing power to advance cutting-edge scientific research on topics related to health and sustainability.',
        social_image: '/images/logo_images/wcg_logo_square_new_200x200.png',
        background_image: {
          url: '/images/prismic/transitionBig.jpg',
        },
        headline: [
          {
            type: 'paragraph',
            text: 'Celebrating 20 years of World Community Grid',
            spans: [],
          },
          {
            type: 'paragraph',
            text: '',
            spans: [],
          },
        ],
        summary: 'Enabling seemingly impossible scientific research to come to life',
        text_color: 'White',
        image_overlay_style: 'None',
        body: [
          {
            slice_type: 'learn_more',
            primary: {
              link: 'https://www.worldcommunitygrid.org/about_us/article.s?articleId=816',
            },
          },
          /* prettier-ignore */
          /* This enables the "join" a project button on the home page, uncomment to add it back
          {
            slice_type: 'contribute_link',
            primary: {
              project_short_name: 'opn1',
            },
          },*/
        ],
      },
    },
  ],
}
