import React, {FunctionComponent, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import styled from 'styled-components'
import BaseLayout from '../../../layouts/BaseLayout/BaseLayout'
import {MainContentHeader} from '../../../components/layout/MainContentHeader'
import {setNavigation} from '../../../store/navigation/actions'
import {PageMetadata} from '../../../components/PageMetadata'
import {metadata} from '../../../constants/metadata'
import { Download16 } from '@carbon/icons-react'

const newsletters = [
  {
    date: '2025-01-15',
    title: 'January 2025',
    path: '/images/prismic/WCG_Newsletter_Jan_2025.pdf'
  },
  {
    date: '2024-11-16',
    title: 'November 2024',
    path: '/images/prismic/WCG_Newsletter_Nov_2024.pdf'
  },
  {
    date: '2023-10-06',
    title: 'October 2023',
    path: '/images/prismic/WCG_Newsletter_Oct_2023.pdf'
  },
  {
    date: '2023-04-13',
    title: 'April 2023',
    path: '/images/prismic/WCG_Newsletter_April_2023.pdf'
  },
  {
    date: '2023-02-01',
    title: 'February 2023',
    path: '/images/prismic/WCG_Newsletter_Feb_1_2023.pdf'
  }
]

const PageLayout = styled.div`
  display: flex;
  gap: 2rem;
  padding: 2rem;
  
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const Sidebar = styled.div`
  flex: 0 0 200px;
  background: #f4f4f4;
  padding: 1rem;
  border-radius: 8px;
  
  @media (max-width: 768px) {
    flex: none;
    width: 100%;
  }
`

const StyledNewsletterLink = styled.div<{active: boolean}>`
  padding: 0.75rem;
  margin-bottom: 0.25rem;
  cursor: pointer;
  background: ${props => props.active ? '#e0e0e0' : 'transparent'};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  &:hover {
    background: #e0e0e0;
  }
`

const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`

const PDFContainer = styled.div`
  position: relative;
  flex: 1;
`

const PDFViewer = styled.iframe`
  width: 100%;
  height: calc(100vh - 200px);
  border: none;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  
  @media (max-width: 768px) {
    height: calc(100vh - 300px);
    min-height: 500px;
  }
`

const ErrorMessage = styled.div`
  padding: 2rem;
  text-align: center;
  background: #f4f4f4;
  border-radius: 8px;
  margin-top: 1rem;
  
  p {
    margin-bottom: 1rem;
  }
  
  .download-button {
    display: inline-block;
    padding: 0.75rem 1.5rem;
    background: #0066cc;
    color: white;
    border-radius: 4px;
    text-decoration: none;
    margin-top: 1rem;
    
    &:hover {
      background: #0052a3;
    }
  }
`

const NewsletterHeader = styled(MainContentHeader)`
  h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
`

const DownloadIcon = styled(Download16)`
  width: 16px;
  height: 16px;
  margin-left: 8px;
  color: #666;
  cursor: pointer;
  
  &:hover {
    color: #0066cc;
  }
`

const NewsletterTitle = styled.span`
  flex: 1;
  cursor: pointer;
  color: #333;
  padding-right: 8px;
`

const NewsletterLink = React.memo(({ newsletter, isActive, onSelect }) => (
  <StyledNewsletterLink
    active={isActive}
    onClick={() => onSelect(newsletter)}
  >
    <NewsletterTitle>
      {newsletter.title}
    </NewsletterTitle>
    <a
      href={`${process.env.PUBLIC_URL}${newsletter.path}`}
      download={`WCG_Newsletter_${newsletter.title}.pdf`}
      onClick={(e) => e.stopPropagation()}
      title={`Download ${newsletter.title} Newsletter`}
    >
      <DownloadIcon />
    </a>
  </StyledNewsletterLink>
));

const Newsletters: FunctionComponent = () => {
  const dispatch = useDispatch()
  const [selectedNewsletter, setSelectedNewsletter] = useState(newsletters[0])
  const [isLoading, setIsLoading] = useState(true)
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    dispatch(setNavigation({section: 'Home', type: 'main', highlight: ''}))
  }, [dispatch])

  const handlePDFLoad = () => {
    setIsLoading(false)
  }

  const handlePDFError = () => {
    setIsLoading(false)
    setHasError(true)
  }

  useEffect(() => {
    setIsLoading(true)
    setHasError(false)
  }, [selectedNewsletter])

  const renderPDFContent = () => {
    const downloadLink = (
      <a 
        href={`${process.env.PUBLIC_URL}${selectedNewsletter.path}`}
        target="_blank"
        rel="noopener noreferrer"
        download={`WCG_Newsletter_${selectedNewsletter.title}.pdf`}
        className="download-button"
      >
        Download Newsletter (PDF)
      </a>
    );

    const isMobile = /Android|webOS|iPhone|iPad|iPod/i.test(navigator.userAgent);
    const isFirefox = navigator.userAgent.toLowerCase().includes('firefox');
    
    if (isMobile || isFirefox) {
      return (
        <ErrorMessage>
          <p>
            {isMobile ? 
              "We've detected you're on a mobile device. Our PDF viewer isn't well supported on mobile browsers." :
              "We've detected you're using Firefox. Our PDF viewer uses an iframe which isn't well supported in Firefox."}
            {" "}Please download the newsletter to view it instead.
          </p>
          {downloadLink}
        </ErrorMessage>
      );
    }

    return (
      <>
        <PDFViewer 
          src={`${process.env.PUBLIC_URL}${selectedNewsletter.path}#view=FitH`}
          title={`${selectedNewsletter.title} Newsletter`}
          type="application/pdf"
          allowFullScreen
          onLoad={handlePDFLoad}
          onError={handlePDFError}
        />
        {isLoading && (
          <LoadingOverlay>
            Loading newsletter...
          </LoadingOverlay>
        )}
      </>
    )
  }

  return (
    <BaseLayout>
      <PageMetadata {...metadata.newsletters} />
      <NewsletterHeader title="World Community Grid Newsletters" />
      <PageLayout>
        <Sidebar>
          {newsletters.map((newsletter) => (
            <NewsletterLink
              key={newsletter.path}
              newsletter={newsletter}
              isActive={selectedNewsletter.path === newsletter.path}
              onSelect={setSelectedNewsletter}
            >
              <NewsletterTitle>
                {newsletter.title}
              </NewsletterTitle>
            </NewsletterLink>
          ))}
        </Sidebar>
        <PDFContainer>
          {renderPDFContent()}
        </PDFContainer>
      </PageLayout>
    </BaseLayout>
  )
}

export default Newsletters