import React, {FunctionComponent} from 'react'
import {HeaderGlobalAction} from 'carbon-components-react/lib/components/UIShell'
import {User20} from '@carbon/icons-react'

const HeaderGlobalActionPreferences: FunctionComponent = () => {
  return (
    <HeaderGlobalAction aria-label="Preferences">
      <User20 />
    </HeaderGlobalAction>
  )
}

export default HeaderGlobalActionPreferences
